import React from 'react'
import ContactDetails from './contact-details'
import ContactForm from "../components/contact-form"

const ContactGrid = () => {
  return (
    <section className="uk-section-default uk-padding-remove">
        <div
          className="uk-section uk-section-default uk-padding-remove"
          uk-height-viewport="expand: true"
        >
            <div className="uk-container" style={{ marginTop: "10px"}}>
                <div className="uk-grid-large" uk-grid="true" style={{ marginBottom: "20px"}}>
                    <ContactDetails />
                    <div className="uk-grid-item-match uk-flex-middle uk-width-expand@m">
                    <div className="uk-panel">
                        <div className="uk-margin">
                            <ContactForm />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ContactGrid
