import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import NewsGrid from "../components/news-grid"
import PageAdvert from "../components/page-advert"
import RichText from "../components/rich-text"
import CtaSlider from "../components/cta-slider"

export const query = graphql`
  query Category($slug: String!) {
    articles: allStrapiArticle(
      filter: { category: { slug: { eq: $slug } } }
      sort: { order: DESC, fields: updatedAt }
    ) {
      nodes {
        ...StrapiArticles
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
      slug
      description
      content {
        data
      }
      news_advert_overlays {
        title
        heading
        description
        buttonName
        link
        id
      }
    }
    strapiNews {
      blocks {
        ...StrapiHero
        ...PageAdverts
        ...StrapiSharedRichText
        ...StrapiCallToAction
      }
      seo {
        ...StrapiSeo

        structuredData {
          _type
          name
          url
          email
          contactPoint {
            _type
            availableLanguage
            contactType
            telephone
          }
          sameAs
          author {
            _type
            name		
            email
            position
          }
          updatedAt
          datePublished
         } 
      }
    }
    allStrapiNewsAdvertOverlay {
      nodes {
        title
        heading
        description
        buttonName
        link
        id
        isInternal
        image {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                layout: CONSTRAINED
                placeholder: BLURRED
                aspectRatio: 1.1
              )
            }
          }
        }
      }
    }
  }
`

const Category = ({ data }) => {
  //console.log("Category - data:", data)

  const { blocks, seo } = data.strapiNews
  //console.log("Category - blocks: ", blocks)
  //console.log("Category - seo: ", seo)

  const hero = blocks[0]
  // no heading block[1] category description is used here
  const pageAds = blocks[2]
  const richTextblock = blocks[3]
  const callToAction = blocks[4]  

  const articles = data.articles
  const category = data.category
  //console.log("Category Template - category: ", category)

  const adverts = []

  category.news_advert_overlays.map(news_advert_overlay =>
    //console.log("news_advert_overlay: ", news_advert_overlay)

    data.allStrapiNewsAdvertOverlay.nodes.map(ad => {
      //console.log("news_advert_overlay - ad: ", ad)
      
      if (news_advert_overlay.title === ad.title) {
         adverts.push({ ...ad })
      }
      return ad
    })
  )

  //console.log("Category adverts:", adverts)

  

  return (
    <>
      <Seo
        title={category.description}
        description={`All ${category.description} articles`}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings
        title={category.description}
        description={category.content.data}
        bg="uk-background-default"
      />

      <PageAdvert advert={pageAds.page_adverts[0]} bg={pageAds.page_adverts[0].bg} />

      <NewsGrid articles={articles.nodes} categoryAdverts={adverts} />

      <PageAdvert advert={pageAds.page_adverts[1]} bg={pageAds.page_adverts[1].bg} />

      <RichText content={richTextblock.richTextBody} />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
    </>
  )
}

export default Category
