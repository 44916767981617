import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlockMedia = ({ data }) => {
  //console.log("Data: ", data)
  const isVideo = data.file.mime.startsWith("video")

  //console.log(data.file)
  //const video = data.file.name
  //console.log("Video: ", video)

  return (
    <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container " style={{ marginTop: "20px"}}>
        {/* <h4 className="uk-margin">{data.file.alternativeText}</h4> */}
        {isVideo ? (
          <p>TODO video</p>
        ) : (
          <div 
            className="uk-flex uk-flex-column" 
            //style={{ maxHeight: "825px"}}
          >
            <GatsbyImage
              image={getImage(data.file.localFile)}
              alt={data.file.alternativeText}
              className=" uk-margin-small-bottom"
              style={{ maxHeight: "400px"}}
            />
          </div>
        )}
        <div className="uk-text-center" style={{ marginBottom: "20px"}}>
          <h6 className="uk-margin">{data.file.caption}</h6>
        </div>
        
      </div>
    </section>
  )
}

export default BlockMedia
