import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"

const ArticleCard = ({ article }) => {
  //console.log("Article Card - article: ", article)
  //console.log("Article Card - imageCaption: ", article.imageCaption)
  return (
    <div className="theme-card" style={{ padding: "10px" }}>
      <div
        className="uk-card uk-card-default uk-card-small uk-border-rounded"
        style={{ height: "100%" }}
      >
        <div className="uk-card-media-top">
          <Link to={`/article/${article.slug}`}>
            {article?.cover && (
              <GatsbyImage
                image={getImage(article.cover.localFile)}
                alt={article.cover.alternativeText}
                title={article.imageCaption}
                data-uk-img="target: !.uk-slideshow-items"
              />
            )}
          </Link>
        </div>
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" data-uk-grid="true">
            <div className="uk-width-auto">
              {article?.author?.avatar && (
                <GatsbyImage
                  image={getImage(article.author.avatar.localFile)}
                  alt={article.author.name}
                  title={article.author.name}
                  className="uk-border-circle"
                  style={{ width: "50px", height: "50px" }}
                />
              )}
            </div>
            <div className="uk-width-expand">
                <div>
                  <h6 className="uk-margin-remove-bottom uk-text-bold">
                    {article.author.name}
                  </h6>
                </div>
                <div>
                  <h6 className="uk-text-meta uk-margin-remove-top uk-text-small">
                    <Moment format="MMMM Do YYYY">{article.publishedAt}</Moment>
                  </h6>
                </div>
              
            </div>
          </div>
        </div>
        <div className="uk-card-body">
          <h5 className="uk-margin-remove uk-text-bold">
            {article.cardTitle}
          </h5>
          <p className="uk-text-small uk-text-muted">{article.shortDesc}</p>
          <Link
            className="hover-underline-animation-orange"
            style={{ color: "#0077B6", textDecoration: "none" }}
            to={`/article/${article.slug}`}
          >
           <h5>Read More</h5>
            <span
              className="uk-margin-small-left"
              uk-icon="icon: arrow-right; ratio: 1"
            ></span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
