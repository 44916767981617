import React from 'react'
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FaBlogger } from "react-icons/fa";

const ProjectCardDetail = ({ projects, styleClass }) => {

    //console.log("projects:", projects);
    //console.log("projects page url:", projects.node.projectPagrUrl);

    //{projects.node.projectPageUrl

    return (
        <div className={styleClass}>
          {/* <!-- card --> */}
          <div className="uk-card uk-card-small uk-card-default">
            <div className="uk-card-header">
              <div className="uk-grid uk-grid-small uk-text-small" uk-grid="true">
                <div className="uk-width-expand uk-flex uk-flex-middle">
                  <span className="cat-txt">
                    {projects.node.tech}
                  </span>
                </div>
                <div className="uk-width-auto uk-text-right uk-text-muted  uk-flex uk-flex-middle">
                  <Link to={`/project/${projects.node.projectPageUrl}`} className="cat-txt" style={{marginRight: "5px"}}>
                    <FaBlogger size={22} />
                  </Link>{" "}
                  <span className="cat-txt" uk-icon="icon: clock; ratio: 1" style={{marginRight: "5px"}}></span>
                  <span className="cat-txt">{projects.node.time}</span> 
                </div>
              </div>
            </div>
            <div className="uk-card-media">
              <div className="uk-inline-clip uk-transition-toggle" tabIndex="-1">
                <div className="uk-width-auto">
                  <GatsbyImage
                    image={getImage(projects?.node?.image?.localFile)}
                    alt={projects.node.tech}
                  />
                </div>
                <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-primary">
                  <Link
                    to={`/project/${projects.node.projectPagrUrl}`}
                    //target="_blank"
                    //rel="noopener noreferrer"
                    aria-label="preview-button"
                    alt="preview-button"
                    text="preview-button"
                    //className="uk-button uk-button-default uk-button-small uk-margin uk-margin-medium-top uk-margin-small-right"
                    toggle="button"
                    className="uk-button uk-button-hero uk-button-small uk-text-small uk-animation-fade uk-animation-slide-bottom uk-margin-small-right uk-margin-bottom uk-margin-top"
                    style={{ borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                  >
                    Details
                  </Link>
                  {projects.node.link !== "hosted locally" ? (
                    <a
                      href={projects.node.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="preview-button"
                      alt="preview-button"
                      text="preview-button"
                      //className="uk-button uk-button-default uk-button-small uk-margin uk-margin-medium-top"
                      toggle="button"
                      className="uk-button uk-button-hero uk-button-small uk-text-small uk-animation-fade uk-animation-slide-bottom uk-margin-small-right uk-margin-bottom uk-margin-top"
                      style={{ borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                    >
                    Preview
                  </a>
                  ) : (
                    <span
                      alt="preview-button"
                      text="preview-button"
                      //className="uk-button uk-button-default uk-button-small uk-margin uk-margin-medium-top"
                      toggle="button"
                      className="uk-button uk-button-hero uk-button-small uk-text-small uk-animation-fade uk-animation-slide-bottom uk-margin-small-right uk-margin-bottom uk-margin-top"
                      style={{ color:" #FFFFFF", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                      uk-tooltip="title: Hosted locally and cannot be viewed here; pos: bottom-center"
                      //uk-icon="icon: github; ratio: 1.9"
                    >
                      Preview
                    </span>
                  )}
                  {/* <a
                    href={projects.node.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="preview-button"
                    alt="preview-button"
                    text="preview-button"
                    className="uk-button uk-button-default uk-button-small uk-margin uk-margin-medium-top"
                  >
                    Preview
                  </a> */}
                  <div />
                  <span uk-icon="icon: heart; ratio: 1"></span> 225{" "}
                  <span uk-icon="icon: comment; ratio: 1"></span>35
                </div>
              </div>
            </div>
    
            <div className="uk-card-body uk-text-left">
              <h6 className="uk-margin-small-bottom  uk-text-bold">
                {projects.node.title}
              </h6>
              <p className="uk-text-small uk-text-muted uk-margin-remove-top">
                {projects.node.description}
              </p>
              <div className="project-stack">
                {projects.node.stack.map((item, index) => {
                  //console.log("Item:", item)
                  return <span key={index}>{item.title}</span>;
                })}
              </div>
            </div>
    
            <div className="uk-card-footer">
              <div
                className="
                  uk-grid 
                  uk-grid-small 
                  uk-grid-divider 
                  uk-flex 
                  uk-flex-between
                  //uk-flex-middle
                "
                uk-grid="true"
              >
                {/* <div className="uk-width-expand uk-text-small">Herman Adu</div> */}
                <div className="uk-width-auto uk-text-right">
                  <div className="uk-flex uk-dark">
                    <a
                      uk-tooltip="title: Twitter; pos: bottom-center"
                      href="https://twitter.com/herman_adu"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="twitter"
                      alt="twitter"
                      text="twitter"
                      className="uk-icon-button uk-margin-small-right"
                      uk-icon="icon: twitter; ratio: 1"
                    >
                      {null}
                    </a>
                    <a
                      uk-tooltip="title: Linkedin; pos: bottom-center"
                      href="https://www.linkedin.com/in/herman-adu/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="linkedin"
                      alt="linkedin"
                      text="linkedin"
                      className="uk-icon-button uk-margin-small-right"
                      uk-icon="icon: linkedin; ratio: 1"
                    >
                      {null}
                    </a>
                    <a
                      uk-tooltip="title: Facebook; pos: bottom-center"
                      href="https://www.facebook.com/AduWebDev/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                      alt="facebook"
                      text="facebook"
                      className="uk-icon-button uk-margin-small-right"
                      uk-icon="icon: facebook; ratio: 1"
                    >
                      {null}
                    </a>
                  </div>
                </div>
                <div className="uk-width-auto uk-text-right">
                  {projects.node.github !== "Private Repository" ? (
                    <a
                      uk-tooltip="title: GitHub Repository; pos: bottom-center"
                      href={projects.node.github}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="github"
                      alt="github Repository"
                      text="github Repository"
                      className="uk-icon-button"
                      uk-icon="icon: github; ratio: 1"
                    >
                      {null}
                    </a>
                  ) : (
                    <span
                      className="uk-icon-button"
                      uk-icon="icon: github; ratio: 1"
                      uk-tooltip="title: Private Repository; pos: bottom-center"
                      //uk-icon="icon: github; ratio: 1.9"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
    
          {/* <!-- /card --> */}
        </div>
      );
}

export default ProjectCardDetail
