import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import PageAdvert from "../components/page-advert"
import NewsGrid from "../components/news-grid"
import RichText from "../components/rich-text"
import CtaSlider from "../components/cta-slider"

const NewsPage = () => {
  const { allStrapiArticle, strapiNews } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { order: DESC, fields: updatedAt }) {
        nodes {
          ...StrapiArticles
        }
      }
      strapiNews {
        blocks {
          ...StrapiHero
          ...StrapiHeading
          ...PageAdverts
          ...StrapiSharedRichText
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo
        }
        newsAdvertOverlayContent {
          news_advert_overlays {
            title
            heading
            description
            buttonName
            link
            isInternal
            image {
              alternativeText
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    aspectRatio: 1.1
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const { blocks, seo, newsAdvertOverlayContent } = strapiNews
  //console.log("strapiNews - Blocks: ", blocks)
  
  const hero = blocks[0]
  const headings = blocks[1]
  const adverts = blocks[2]
  const richTextblock = blocks[3]
  const callToAction = blocks[4]

  const articles = allStrapiArticle
  const newsAdverts = newsAdvertOverlayContent  

  const structuredData = seo?.structuredData
  

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings
        title={headings.title}
        description={headings.description}
        bg="uk-background-default"
      />

      <PageAdvert advert={adverts.page_adverts[0]} bg={adverts.page_adverts[0].bg} />

      <NewsGrid 
        articles={articles.nodes} 
        newsAdverts={newsAdverts.news_advert_overlays} 
      />

      <PageAdvert advert={adverts.page_adverts[1]} bg={adverts.page_adverts[0].bg} />

      <RichText content={richTextblock.richTextBody} />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
    </>
  )
}

export default NewsPage
