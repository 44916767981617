import React from 'react'
import ProjectCardDetail from "./project-card-detail";
import { Link } from 'gatsby';
//import Title from "./title";
//import Headings from './headings';

const ProjectsGrid = ({projects}) => {
    //console.log("projects:", projects);

    /* let reactProj = projects.edges.filter(function (project) {
        return project.node.type === "React";
    });

    let gatsbyProj = projects.edges.filter(function (project) {
        return project.node.type === "Gatsby";
    });

    let nextProj = projects.edges.filter(function (project) {
        return project.node.type === "Next";
    });

    let fullStackProj = projects.edges.filter(function (project) {
        return project.node.fullstack === true;
    });
    
    let headlessProj = projects.edges.filter(function (project) {
        return project.node.headless === true;
    });
    
    let mobileProj = projects.edges.filter(function (project) {
        return project.node.type === "mobile";
    }); */

    

    //console.log("React projects:", reactProj);
    //console.log("Gatsby projects:", gatsbyProj);
    //console.log("Next projects:", nextProj);
    //console.log("Full Stack Projects:", fullStackProj);
    //console.log("Headless CMS Projects:", headlessProj)
    //console.log("mobile CMS Projects:", mobileProj);
    


    return (
        <section
          id="featured-projects"
          className="uk-section uk-section-default uk-padding-remove-top"
        >    
          <div className="uk-container ">
            <div uk-filter="target: .js-filter">
              <div className="uk-text-center">
                <ul
                  className="uk-subnav uk-flex uk-flex-center uk-container"
                  uk-switcher="connect: .uk-switcher; animation: uk-animation-fade"
                >
                  <li className="uk-margin-small-bottom uk-padding-remove">
                    <Link
                      to="/"
                      className="uk-button uk-button-default uk-button-small active uk-margin-small-bottom uk-text-small uk-animation-fade uk-animation-slide-top"
                      uk-filter-control="[data-tags='react'], [data-tags='gatsby'], [data-tags='next'], [data-tags*='mobile']"
                      toggle="button"
                      style={{ 
                        borderColor: "#0077B6", 
                        color: "#0077B6",  
                        textDecoration: "none", 
                        paddingTop: "5px", 
                        paddingBottom: "5px"
                      }}
                    >
                      <span
                        style={{ 
                          fontSize: "11px"
                        }}
                      >
                        All
                      </span>                  
                    </Link>
                  </li>
                  <li className="uk-margin-small-bottom uk-padding-remove">
                    <Link
                      to="/"
                     className="uk-button uk-button-default uk-button-small active uk-margin-small-bottom uk-text-small uk-animation-fade uk-animation-slide-top"
                      uk-filter-control="[data-tags='react']"
                      toggle="button"
                      style={{ 
                        borderColor: "#0077B6", 
                        color: "#0077B6", 
                        textDecoration: "none", 
                        paddingTop: "5px", 
                        paddingBottom: "5px"
                      }}
                    >
                      <span
                        style={{ 
                          fontSize: "11px"
                        }}
                      >
                        React JS
                      </span>                  
                    </Link>
                  </li>
                  <li className="uk-margin-small-bottom uk-padding-remove">
                    <Link
                      to="/"
                     className="uk-button uk-button-default uk-button-small active uk-margin-small-bottom uk-text-small uk-animation-fade uk-animation-slide-top"
                      uk-filter-control="[data-tags='gatsby']"
                      toggle="button"
                      style={{ 
                        borderColor: "#0077B6", 
                        color: "#0077B6",  
                        textDecoration: "none", 
                        paddingTop: "5px", 
                        paddingBottom: "5px"
                      }}
                    >
                      <span
                        style={{ 
                          fontSize: "11px"
                        }}
                      >
                        Gatsby JS
                      </span>         
                    </Link>
                  </li>
                  <li className="uk-margin-small-bottom uk-padding-remove">
                    <Link
                      to="/"
                     className="uk-button uk-button-default uk-button-small active uk-margin-small-bottom uk-text-small uk-animation-fade uk-animation-slide-top"
                      uk-filter-control="[data-tags='next']"
                      toggle="button"
                      style={{ 
                        borderColor: "#0077B6", 
                        color: "#0077B6",  
                        textDecoration: "none", 
                        paddingTop: "5px", 
                        paddingBottom: "5px"
                      }}
                    >
                      <span
                        style={{ 
                          fontSize: "11px"
                        }}
                      >
                        Next JS
                      </span>        
                    </Link>
                  </li>
                  <li className="uk-margin-small-bottom uk-padding-remove">
                    <Link
                      to="/"
                     className="uk-button uk-button-default uk-button-small active uk-margin-small-bottom uk-text-small uk-animation-fade uk-animation-slide-top"
                      uk-filter-control="[data-tags*='mobile']"
                      toggle="button"
                      style={{ 
                        borderColor: "#0077B6", 
                        color: "#0077B6",  
                        textDecoration: "none", 
                        paddingTop: "5px", 
                        paddingBottom: "5px"
                      }}
                    >
                      <span
                        style={{ 
                          fontSize: "11px"
                        }}
                      >
                        Mobile
                      </span>      
                    </Link>
                  </li>
                </ul>
              </div>

              <ul
                className="js-filter uk-child-width-1-1@s uk-child-width-1-2@m  uk-child-width-1-3@l uk-text-center"
                uk-grid="true"
              >                
                {projects.edges.map((project, index) => {
                  //console.log("Project Item: ", project)
                  return (
                    <li key={index} data-tags={`${project.node.dataTag}`}>
                      <ProjectCardDetail projects={project} styleClass={`${project.node.styleClass}`} /> 
                    </li>
                  )
                })}
              </ul>
    
              {/* <ul
                className="js-filter uk-child-width-1-1@s uk-child-width-1-2@m  uk-child-width-1-3@l uk-text-center"
                uk-grid="true"
              >
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[0]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[1]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[2]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[3]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[4]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[5]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[6]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[7]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[8]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[9]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[10]} styleClass="design-card" />
                </li>
                <li data-tags="react">
                  <ProjectCardDetail projects={reactProj[11]} styleClass="design-card" />
                </li>
                <li data-tags="gatsby">
                  <ProjectCardDetail projects={gatsbyProj[0]} styleClass="nature-card" />
                </li>
                <li data-tags="gatsby">
                  <ProjectCardDetail projects={gatsbyProj[1]} styleClass="nature-card" />
                </li>
                <li data-tags="gatsby">
                  <ProjectCardDetail projects={gatsbyProj[2]} styleClass="nature-card" />
                </li>
                <li data-tags="gatsby">
                  <ProjectCardDetail projects={gatsbyProj[3]} styleClass="nature-card" />
                </li>
                <li data-tags="gatsby">
                  <ProjectCardDetail projects={gatsbyProj[4]} styleClass="nature-card" />
                </li>
                <li data-tags="gatsby">
                  <ProjectCardDetail projects={gatsbyProj[5]} styleClass="nature-card" />
                </li>
                <li data-tags="gatsby">
                  <ProjectCardDetail projects={gatsbyProj[6]} styleClass="nature-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[0]} styleClass="music-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[1]} styleClass="music-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[2]} styleClass="music-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[3]} styleClass="music-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[4]} styleClass="music-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[5]} styleClass="music-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[6]} styleClass="music-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[7]} styleClass="music-card" />
                </li>
                <li data-tags="next">
                  <ProjectCardDetail projects={nextProj[8]} styleClass="music-card" />
                </li>
                <li data-tags="mobile">
                  <ProjectCardDetail projects={mobileProj[0]} styleClass="mobile-card" />
                </li>
                <li data-tags="mobile">
                  <ProjectCardDetail projects={mobileProj[1]} styleClass="mobile-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[0]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[1]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[2]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[3]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[4]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[5]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[6]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[7]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[8]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[9]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[10]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[11]} styleClass="fullstack-card" />
                </li>
                <li data-tags="fullstack">
                  <ProjectCardDetail projects={fullStackProj[12]} styleClass="fullstack-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[0]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[1]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[2]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[3]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[4]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[5]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[6]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[7]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[8]} styleClass="headless-card" />
                </li>
                <li data-tags="headless">
                  <ProjectCardDetail projects={headlessProj[9]} styleClass="headless-card" />
                </li>
              </ul> */}
            </div>
          </div>
        </section>
      );
}

export default ProjectsGrid
