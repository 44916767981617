import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const BlockSlider = ({ data }) => {
  //console.log("Data: ", data)

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          //initialSlide: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="uk-section uk-section-default uk-padding-remove">
      <div className="uk-container uk-container-small uk-margin uk-margin-medium-bottom">
        <Slider {...settings}>
          {data.files.map(file => (
            <figure
              key={file.id}
              className="uk-text-center uk-margin-small-top"
            >
              <div
                className="uk-inline-clip uk-transition-toggle"
                tabIndex="-1"
              >
                <GatsbyImage
                  image={getImage(file.localFile)}
                  alt={file.alternativeText}
                />
                <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-primary">
                  <p className="uk-h4 uk-margin-remove orange-grad uk-text-bold">
                    {file.caption}
                  </p>
                </div>
              </div>
              <figcaption className="uk-margin-small-top ">
              James Walter Raymond Ltd
              </figcaption>
            </figure>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default BlockSlider
