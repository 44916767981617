import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import CtaSlider from "../components/cta-slider"
import PageAdvert from "../components/page-advert"
import RichText from "../components/rich-text"
import Media from "../components/media"
import ArticleSlider from "../components/article-slider"


const PartnersPage = () => {
    const { strapiPartner } = useStaticQuery(graphql`
    query {
      strapiPartner {
        blocks {
          ...StrapiHero
          ...StrapiHeading
          ...PageAdverts
          ...StrapiSharedRichText
          ...StrapiSharedMedia
          ...ArticleSlider
          ...StrapiSharedRichText
          ...StrapiCallToAction
        }       
        seo {
          ...StrapiSeo            
        }
      }
    }
  `)

  const { blocks, seo } = strapiPartner
  //console.log("strapiPartner - blocks: ", blocks)
  
  const hero = blocks[0]
  const heading = blocks[1]
  const adverts = blocks[2]
  const content = blocks[3]
  const data = blocks[4]
  const content2 = blocks[5]
  const articleSlider = blocks[6]
  const content3 = blocks[7]
  const callToAction = blocks[8]

  // Helmet data seo
  const structuredData = seo.structuredData
 
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={strapiPartner.seo.metaTitle}
        description={strapiPartner.seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={strapiPartner.seo.metaSocial}
        metaRobots={strapiPartner.seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings
          title={heading.title}
          description={heading.description}
          bg={heading.bg}
      />

      <PageAdvert 
        advert={adverts.page_adverts[0]} 
        bg={adverts.page_adverts[0].bg}
      />

      {/* <PageAdvert 
        advert={adverts.page_adverts[1]} 
        bg={adverts.page_adverts[1].bg}
      /> */}

      <RichText content={content.richTextBody} />

      <Media data={data} />

      <RichText content={content2.richTextBody} />

      <ArticleSlider 
        articles={articleSlider.articles} 
        title={articleSlider.title} 
        description={articleSlider.description} 
        bg={articleSlider.bg} 
      />

      <RichText content={content3.richTextBody} />
      
      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
    </>
  )
}

export default PartnersPage