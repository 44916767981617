import React from "react"
import Markdown from "react-markdown"

const RichText = ({ content }) => {
    const { data } = content

    return (
        <section className="uk-section uk-padding-remove"  style={{ marginBottom: "20px"}}>
            <div className="uk-container">
                <Markdown
                    children={data.body}
                   
                />
            </div>
        </section>
    )
}

export default RichText
