import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import ArticleSlider from "../components/article-slider"
import SwitcherButtonsContent from "../components/switcher-buttons-content"
import PageAdvert from "../components/page-advert"
import CtaSlider from "../components/cta-slider"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import ReactLightbox from "../components/react-lightbox"
import FeaturedComponent from "../components/featured-component"
import RichText from "../components/rich-text"

const IndexPage = () => {

  const {
    strapiHome
  } = useStaticQuery(graphql`
    query {
      strapiHome {
        blocks {
          ...StrapiHero
          ...StrapiHeading
          ...SwitcherSections
          ...PageAdverts
          ...TestimonialCards
          ...StrapiGallery
          ...StrapiFeaturedComponent
          ...ArticleSlider
          ...StrapiSharedRichText
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo
        }
      }
    }
  `)

  const { blocks, seo } = strapiHome
  //console.log("Home - Blocks: ", blocks)

  const hero = blocks[0]
  const heading = blocks[1]
  const switcher = blocks[2]
  const adverts = blocks[3]
  const testimonials = blocks[4]
  const gallery = blocks[5]
  const achievements = blocks[6]
  const articleSlider = blocks[7]
  const richTextblock = blocks[8]
  const callToAction = blocks[9]

  // Helmet data seo
  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />
      
      <Hero hero={hero} />   

      <Headings
        title={heading.title}
        description={heading.description}
        bg={heading.bg}
      />   

      <SwitcherButtonsContent 
        switcherSections={switcher.switcher_sections}
        bg={switcher.bg}
      />

      <PageAdvert 
        advert={adverts.page_adverts[0]} 
        bg={adverts.page_adverts[0].bg}
      />

      <PageAdvert 
        advert={adverts.page_adverts[1]} 
        bg={adverts.page_adverts[1].bg}
      />

      <TestimonialSliderBackgroundImage 
        backgroundImage={testimonials.backgroundImage}
        testimonial_cards={testimonials.testimonial_cards}
      />

      <FeaturedComponent 
        sections={achievements.featured_component_sections}
        title={achievements.title}
        description={achievements.description}
        bg={achievements.bg} 
      />
      
      <ReactLightbox
        images={gallery.files}
        title={gallery.title}
        description={gallery.description}
        bg={gallery.bg}
        //remainingImages={remainingImages}
      />

      <ArticleSlider 
        articles={articleSlider.articles} 
        title={articleSlider.title} 
        description={articleSlider.description} 
        bg={articleSlider.bg} 
      />

      <RichText content={richTextblock.richTextBody} />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
    </>
  )
}

export default IndexPage
