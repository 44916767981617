import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Headings from './headings'

const Timeline = ({ sections, title, description, bg = "uk-background-default" }) => {

    return (
        <div
            id="timeline"
            className={`uk-section ${bg} uk-padding-remove`}
        >
        <div className="uk-container">
            <Headings
                title={title}
                description={description}
                bg={bg}
            />
            <div
                className="uk-slider-container-offset  removeOpacity  uk-padding-remove"
                uk-slider="true"
                style={{ marginBottom: "30px"}}
            >
                <div className="uk-position-relative uk-visible-toggle" tabIndex="-1">
                    <ul 
                        className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid"
                        data-uk-grid
                        data-uk-scrollspy="target: > li; delay: 150; cls: uk-animation-slide-bottom-medium"
                    >
                        {sections.map((item, index) => {
                        //console.log("Item: ", item)

                        const { image, title, description } = item

                        return (
                            <li key={index}>
                                <div>
                                    <div className="uk-text-center uk-padding-small">
                                        {image?.localFile && (
                                            <GatsbyImage
                                                image={getImage(image.localFile.childImageSharp.gatsbyImageData)}
                                                 alt={title}
                                                title={title}
                                                data-uk-img="target: !.uk-slideshow-items"
                                            />
                                        )}                                   
                                    </div>

                                    <div className="uk-padding-small">
                                        <div className="uk-text-center uk-heading-line" style={{ marginBottom: "20px"}}>
                                            <h4 className="uk-text-center">
                                                <span>{title}</span>
                                            </h4>
                                        </div>
                                    
                                        <div className="uk-text-center">
                                            <p>{description}</p>
                                        </div>
                                    </div>

                                  
                                </div>
                            </li>
                        )
                    })}
                    </ul>
                    
                    <a
                        className="uk-position-center-left-out uk-hidden-hover uk-margin"
                        href="/"
                        uk-slidenav-previous="true"
                        uk-slider-item="previous"
                        aria-label="previous"
                    >
                        {null}
                    </a>
                    <a
                        className="uk-position-center-right-out uk-hidden-hover uk-margin"
                        href="/"
                        uk-slidenav-next="true"
                        uk-slider-item="next"
                        aria-label="next"
                    >
                        {null}
                    </a>
                </div>

            <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin-small-top uk-margin-small-bottom"></ul>
            </div>
        </div>
        </div>
    )
}

export default Timeline