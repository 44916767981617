import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SwitcherButtonsContent = ({ switcherSections, bg = "uk-background-muted" }) => {
  //console.log("Switcher Content: ", switcherSections)

  // switcherSections.nodes.map((section, index) => {
  //   console.log("switcher section: ", section.image[0].localFile)
  // })

  // switcherSections.nodes.map((switcherContent, index) => {
  //   console.log("switcher content: ", switcherContent)
  // })

  return (
    <section className={`uk-section ${bg} uk-padding-remove`}>
      <div className="uk-container uk-margin-medium-bottom">
        <div>
          <div className="uk-container uk-margin-small-left">
            <ul
              className="uk-subnav uk-flex uk-flex-center uk-container"
              uk-switcher="connect: .uk-switcher; animation: uk-animation-fade"
            >
              {switcherSections.map((section, index) => (
                <li key={`section__${index}`} className="uk-margin-small-bottom uk-padding-remove">
                  <Link 
                    to={section.switcherButton.link}
                    className="uk-button uk-button-default uk-button-small active uk-margin-small-bottom uk-text-small uk-animation-fade uk-animation-slide-top"
                    toggle="button"
                    style={{  borderColor: "#0077B6", color: "#0077B6", textDecoration: "none", paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <span>{section.switcherButton.name}</span>                  
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <ul className="uk-switcher uk-margin-small-top">
          {switcherSections.map((section, index) => {
            //console.log("section: ", section)

            const leftContent = section.switcherLinkContent[0]
            const rightContent = section.switcherLinkContent[1]

            return (
              <li key={`section__${index}`}>
                <div
                  className="uk-grid uk-child-width-1-2@l uk-flex-middle"
                  uk-grid="true"
                  uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium"
                >
                  <div>
                    <GatsbyImage
                      image={getImage(section.image[0].localFile)}
                      alt={section.image[0].alternativeText}
                      style={{ width: "100%", height: "100%" }}
                    />                    
                  </div>                  
                    <div uk-scrollspy-class="uk-animation-slide-right-medium">
                      <div className="uk-margin-small-bottom">
                        <h4 className="uk-margin-remove">
                          {section.smallTitle}
                        </h4>
                      </div>
                      <div className="uk-margin-small-bottom">
                        <h5 className="uk-margin-remove">
                          {section.largeTitle}
                        </h5>
                      </div>
                      <div className="uk-margin-small-bottom">
                        <p className="uk-margin-remove">
                          {section.description}
                        </p>
                      </div>
                      <div className="uk-margin-small-bottom">
                        <Link
                          to={section.link}
                          alt={`button that links to our ${leftContent.name}`}
                          title={`Click here to learn more about our ${leftContent.name}`}
                          className="hover-underline-animation-orange"
                          //data-uk-icon="arrow-right"
                          style={{ color: "#0077B6", textDecoration: "none", marginBottom: "10px", fontWeight: "600" }}
                        >
                          {/* <span className="uk-margin-small-right uk-text-capitalize">
                              {leftContent.name}
                          </span> */}

                          <h5>{leftContent.name}</h5>
                          <span
                            className="uk-margin-small-left"
                            uk-icon="icon: arrow-right; ratio: 1"
                          ></span>
                          
                        </Link>
                      </div>

                      <div
                        className="uk-grid uk-child-width-1-2@s"
                        uk-grid="true"
                      >
                        <div>
                          <div className="uk-margin-small-bottom">
                            <h5 className="uk-margin-remove">
                              {leftContent.title}
                            </h5>
                          </div>
                          <div className="uk-margin-small-bottom">
                            <p className="uk-margin-remove">
                              {leftContent.description}                            
                            </p>
                          </div>
                          <div>
                            <Link
                                to={leftContent.link}
                                alt={`button that links to our ${leftContent.name}`}
                                title={`Click here to learn more about our ${leftContent.name}`}
                                className="hover-underline-animation-orange"
                                //data-uk-icon="arrow-right"
                                style={{ color: "#0077B6", textDecoration: "none", fontWeight: "600" }}
                              >
                                <h5 className="uk-margin-small-right uk-text-capitalize">
                                  {leftContent.name}
                                </h5>
                                <span
                                  className="uk-margin-small-left"
                                  uk-icon="icon: arrow-right; ratio: 1"
                                ></span>
                            </Link>
                          </div>
                        </div>
                        <div>
                          <div className="uk-margin-small-bottom">
                            <h5 className="uk-margin-remove">
                              {rightContent.title}
                            </h5>
                          </div>

                          <div className="uk-margin-small-bottom">
                            <p className="uk-margin-remove">
                            {rightContent.description}                     
                            </p>
                          </div>

                          <div>
                            <Link
                              to={rightContent.link}
                              alt={`button that links to our ${rightContent.name}`}
                              title={`Click here to learn more about our ${rightContent.name}`}
                              className="hover-underline-animation-orange"
                              //data-uk-icon="arrow-right"
                              style={{ color: "#0077B6", textDecoration: "none", fontWeight: "600" }}
                            >
                              <h5 className="uk-margin-small-right uk-text-capitalize">
                                {rightContent.name}
                              </h5>   
                              <span
                                  className="uk-margin-small-left"
                                  uk-icon="icon: arrow-right; ratio: 1"
                                ></span>                           
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default SwitcherButtonsContent
