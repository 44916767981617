import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Media = ({ data }) => {

    //console.log("Data: ", data)
  const isVideo = data.file.mime.startsWith("video")

    //console.log(data.file)
    //const video = data.file.name
    //console.log("Video: ", video)
    return (
        <section className="uk-section uk-section-default uk-padding-remove">
            <div className="uk-container " style={{ marginTop: "20px"}}>
                {isVideo ? (
                    <p>TODO video</p>
                ) : (
                    <div className="uk-flex uk-flex-column" style={{ maxHeight: "425px"}}>
                        <GatsbyImage
                            image={getImage(data.file.localFile)}
                            alt={data.file.alternativeText}
                            className=" uk-margin-small-bottom"
                        />
                    </div>
                )}

                <div className="uk-text-center">
                    <h6 className="uk-margin">{data.file.caption}</h6>
                </div>        
            </div>
        </section>
    )
}

export default Media
