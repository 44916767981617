import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import BlocksRenderer from "../components/blocks-renderer"
import { Helmet } from "react-helmet"

const PrivacyPolicy = () => {
  const { strapiPrivacy } = useStaticQuery(graphql`
    query {
      strapiPrivacy {
        blocks {
          ...Blocks
        }
        #hero {
        #  ...StrapiHero
        #}
        seo {
          ...StrapiSeo
        }
        #headings {
        #  title
        #  description
        #}
        blocks {
          ...Blocks
        }
      }
    }
  `)
  //console.log("strapiTerm: ", strapiTerm)

  const { blocks, seo } = strapiPrivacy

  const structuredData = strapiPrivacy.seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />
      
      <section className="uk-section-default">
        <BlocksRenderer blocks={blocks} />
      </section>
    </>
  )
}

export default PrivacyPolicy
