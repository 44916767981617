import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Headings from './headings'

const FeaturedComponent = ({ sections, title, description, bg = "uk-background-muted" }) => {
    //console.log("Featured bg: ", bg)

    return (
        <section
          id="featured"
          className={`uk-section ${bg} uk-padding-remove`}
        >

          <Headings
            title={title}
            description={description}
            bg={bg}
          />
    
          <div className="uk-container" style={{ marginBottom: "30px"}}>
            <hr className="uk-divider-icon" />
            <div
              className="uk-grid uk-grid-large uk-child-width-1-3@m"
              data-uk-grid
              data-uk-scrollspy="target: > div; delay: 150; cls: uk-animation-slide-bottom-medium"
            >
              {sections.map((item, index) => {
                const { image, title, content } = item
    
                return (
                  <div
                    key={index}
                    alt={title}
                    title={title}
                    className="uk-text-center"
                  >
                    <div className="uk-text-large" style={{ color: "#ff7f00" }}>
                      {image?.localFile && (
                        <GatsbyImage
                            image={getImage(image.localFile.childImageSharp.gatsbyImageData)}
                            style={{ marginBottom: "20px" }}
                            alt={title}
                            title={title}
                            data-uk-img="target: !.uk-slideshow-items"
                        />
                      )}
                    </div>
                    <h5 className=" uk-margin-remove-top">{title}</h5>
                    <p>{content.data.content}</p>
                  </div>
                )
              })}
            </div>
            <hr className="uk-divider-icon" />
          </div>
        </section>
      )
}

export default FeaturedComponent