import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import Headings from "./headings"
import { Link } from "gatsby"

const AboutMe = ({ aboutMe , bg= "uk-background-default"}) => {
    //console.log("About Me: ", aboutMe)
    return (
        <section className={`uk-section ${bg} uk-padding-remove-top uk-padding-remove-bottom`}>
            
        <Headings
            title={aboutMe.title}
            description={aboutMe.description}
            bg={bg}
        />

        <div className="uk-section uk-padding-remove-top uk-margin-small-top">
            <div className="uk-container">
                <div
                    className="uk-grid-collapse uk-child-width-1-2@m  uk-flex-middle uk-background-primary uk-light"
                    data-uk-grid="true"
                    style={{ backgroundColor: "#0077B6"}}
                >
                    <div className="uk-flex-last@m uk-inline">
                        <div>
                            {aboutMe.image?.localFile && (
                                <GatsbyImage
                                    image={getImage(aboutMe.image.localFile.childImageSharp.gatsbyImageData)}
                                    alt="image of Herman adu"
                                    title="image of Herman adu"
                                    /* style={{ 
                                        maxHeight: "780px",
                                        //maxWidth: "800",
                                    }} */
                                    loading="eager"
                                />
                            )}
                            {/* <div className="uk-overlay-primary uk-position-cover"></div> */}
                            <div className="uk-overlay uk-overlay-primary uk-text-center uk-position-bottom uk-padding-remove">
                                {/* <p>Herman Adu Bio, click below to contact me!</p> */}
                                <Link
                                    //key={button.strapi_id}
                                    to="/contact-me"
                                    title="Contact me button"
                                    alt="Contact me button"
                                    //rel="noopener noreferrer"
                                    //target="_blank"
                                    toggle="button"
                                    className="uk-button uk-button-hero uk-button-small uk-text-small uk-animation-fade uk-animation-slide-bottom uk-margin-small-right uk-margin-bottom uk-margin-top"
                                    style={{ borderColor: "#f7a859", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                                >
                                    <span>Contact me</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className=""> */}
                    <div className="uk-padding uk-margin"style={{ color: "#FFFFFF"}} >
                        {/* <h2 className="uk-h1 uk-card-title">{aboutSection[0].title}</h2> */}
                        <ReactMarkdown children={aboutMe.content.data.content} />
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    </section>
    )
}

export default AboutMe
