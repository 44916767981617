import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Headings from './headings'
import { Link } from 'gatsby'

const Education = ({ sections, title, description, bg = "uk-background-muted" }) => {
    //console.log("Education - education", education)

    return (
        <section
            id="education"
            className={`uk-section ${bg} uk-padding-remove`}
        >
            <Headings
                title={title}
                description={description}
                bg={bg}
            />
                <div className="uk-section uk-padding-remove" style={{ marginBottom: "30px"}}>
                    <div className="uk-container ">
                        <div className="uk-visible-toggle removeOpacity" uk-slider="autoplay: true">
                            <div className="uk-position-relative">
                                <div className="uk-slider-container uk-box-shadow-medium">
                                <ul className="uk-slider-items uk-child-width-1-1">
                                    {sections.map(item => {
                                    //console.log("Item: ", item)
                                    const { id, image, uni, titleH2, titleH3, content } = item

                                    return (
                                        <li key={id}>
                                        <div 
                                            //className="uk-grid-collapse" 
                                            uk-grid="true"
                                        >
                                            <div className="uk-width-1-2@m">
                                            {image?.localFile && (
                                                <GatsbyImage
                                                    image={getImage(image.localFile.childImageSharp.gatsbyImageData)}
                                                    alt={titleH2}
                                                    title={titleH2}
                                                    data-uk-img="target: !.uk-slideshow-items"
                                                    style={{
                                                        height: "100%",
                                                        //width: "100%",
                                                    }}
                                                    loading="lazy"
                                                />
                                            )}
                                            </div>
                                            <div className="uk-width-expand@s uk-flex uk-flex-middle">
                                            <div className="uk-padding">
                                                <div>
                                                    <h5 className="uk-text-uppercase uk-letter-spacing-small uk-margin-small-bottom">
                                                        {uni}
                                                    </h5>
                                                </div>

                                                <div>
                                                    <h5 className=" uk-margin-small-top uk-margin-small-bottom">
                                                        {titleH2}
                                                    </h5>
                                                </div>

                                                <div>
                                                    <h5 className="uk-margin-small-top uk-margin-small-bottom">
                                                        {titleH3}
                                                    </h5>
                                                </div>
                                               
                                                
                                                
                                                <div className="uk-margin-small-top ">
                                                    <p>{content}</p>
                                                </div>
                                                <hr className="uk-margin-small-top uk-separator-small" />
                                                <div className="uk-margin-small-bottom">
                                                <Link to="/" className="hover-underline-animation-orange" style={{textDecoration: "none"}}>
                                                    <h5>View Details</h5>
                                                    <span
                                                    className="uk-margin-small-left"
                                                    uk-icon="icon: arrow-right; ratio: 1"
                                                    ></span>
                                                </Link>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </li>
                                    )
                                    })}
                                </ul>
                                </div>

                                {/* <div className="uk-hidden@l">
                                    <a
                                        className="uk-position-center-left uk-position-small"
                                        href="/"
                                        uk-slidenav-previous="true"
                                        uk-slider-item="previous"
                                        aria-label="previous"
                                    >
                                        {null}
                                    </a>
                                    <a
                                        className="uk-position-center-right uk-position-small"
                                        href="/"
                                        uk-slidenav-next="true"
                                        uk-slider-item="next"
                                        aria-label="next"
                                    >
                                        {null}
                                    </a>
                                </div> */}
                                
                                <div className="uk-visible@m">
                                    <a
                                        className="uk-position-center-left-out uk-slidenav-small"
                                        href="/"
                                        uk-slidenav-previous="true"
                                        uk-slider-item="previous"
                                        aria-label="previous"
                                    >
                                        {null}
                                    </a>
                                    <a
                                        className="uk-position-center-right-out uk-slidenav-small"
                                        href="/"
                                        uk-slidenav-next="true"
                                        uk-slider-item="next"
                                        aria-label="next"
                                    >
                                        {null}
                                    </a>
                                </div>

                                <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin-medium-top"></ul>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Education
