import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import PageAdvert from "../components/page-advert"
import SliderSwitcherContent from "../components/slider-switcher-content"
import ArticleSlider from "../components/article-slider"
import CtaSlider from "../components/cta-slider"
import RichText from "../components/rich-text"

const ClientsPage = () => {
    const { 
        strapiClient,
    } =
    useStaticQuery(graphql`
        query {
            strapiClient {
                blocks {
                    ...StrapiHero
                    ...StrapiHeading
                    ...PageAdverts
                    ...SliderSwitcherContent
                    ...ArticleSlider
                    ...StrapiSharedRichText
                    ...StrapiCallToAction
                }
                seo {
                    ...StrapiSeo            
                }
            }
        }
    `)
    
    const { blocks, seo } = strapiClient
    //console.log("strapiClient - blocks: ", blocks)
    
    const hero = blocks[0]
    const heading = blocks[1]
    const adverts = blocks[2]
    const sliderSwitcher = blocks[3]
    const articleSlider = blocks[4]
    const content = blocks[5]
    const callToAction = blocks[6]

    // Helmet data seo
    const structuredData = seo.structuredData

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "website",
                        structuredData,
                    })}
                </script>
            </Helmet>

            <Seo
                title={seo.metaTitle}
                description={seo.metaDescription}
                image={seo.metaImage.localFile.publicURL}
                metaSocial={seo.metaSocial}
                metaRobots={seo.metaRobots}
            />

            <Hero hero={hero} />

            <Headings
                title={heading.title}
                description={heading.description}
                bg={heading.bg}
            />

            <PageAdvert 
                advert={adverts.page_adverts[0]} 
                bg={adverts.page_adverts[0].bg}
            />

            <PageAdvert 
                advert={adverts.page_adverts[1]} 
                bg={adverts.page_adverts[1].bg}
            />

            <SliderSwitcherContent 
                title={sliderSwitcher.title}
                description={sliderSwitcher.description}
                sections={sliderSwitcher.slider_switcher_content_sections}
                bg={sliderSwitcher.bg}
            />

            <ArticleSlider 
                articles={articleSlider.articles} 
                title={articleSlider.title} 
                description={articleSlider.description} 
                bg={articleSlider.bg} 
            />

            <RichText content={content.richTextBody} />

            <CtaSlider 
                title={callToAction.title} 
                description={callToAction.description} 
                callToActionSections={callToAction.call_to_action_sections} 
                bg={callToAction.bg} 
            />
        </>
    )
}

export default ClientsPage
