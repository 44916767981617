import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import ArticleSlider from "../components/article-slider"
import RichText from "../components/rich-text"
import CtaSlider from "../components/cta-slider"

const FaqsPage = () => {
  const { strapiFaq } = useStaticQuery(graphql`
    query {
      strapiFaq {
        blocks {
          ...StrapiHero
          ...StrapiHeading
          ...ArticleSlider
          ...StrapiSharedRichText
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo
          structuredData {
            mainEntity {
              _type
              acceptedAnswer {
                _type
                text
              }
            }
          }
        }
      }
    }
  `)

  //console.log("strapiFaq: ", strapiFaq)

  const { blocks, seo } = strapiFaq
  //console.log("strapiFaq - Blocks: ", blocks)

  const hero = blocks[0]
  const headings = blocks[1]
  const articleSlider = blocks[2]
  const richTextblock = blocks[3]
  const callToAction = blocks[4]

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-small-bottom">
          <Headings title={headings.title} description={headings.description} />
          <div className="uk-margin-medium-bottom" style={{ marginTop: "20px"}}>
            <div uk-grid="true" className="uk-grid-match uk-child-width-1-2@s">
              <div className="theme-card">
                <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                  <div
                    className="uk-card-badge uk-label"
                    style={{
                      background: "linear-gradient(45deg, #023E8A, #CAF0F8)",
                      fontWeight: "600" 
                    }}
                  >
                    FAQ
                  </div>
                  <div className="uk-margin-small-top">
                    <h4
                      className="uk-margin-right uk-margin-remove "
                      style={{ fontWeight: "700" }}
                    >
                      <span className="blue-grad-faqs">Why Jamstack Solutions - FAQs</span>
                    </h4> 
                  </div>
                  
                  <p>
                    Jamstack is an architectural approach that decouples the web experience layer from data and 
                    business logic, improving flexibility, scalability, performance, and maintainability.
                  </p>

                  <p>
                    Jamstack removes the need for business logic to dictate the web experience.
                  </p>

                  <p>
                    It enables a composable architecture for the web where custom logic and 3rd party services are consumed through APIs.
                  </p>

                  {/* This is an anchor toggling the modal */}

                  <button
                    className="uk-button uk-button-default uk-button-small uk-text-small buttonTitle"
                    style={{ color: "#0077B6", borderColor: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                    data-uk-icon="arrow-right"
                    uk-toggle="target: #modal-why-jamstack-solutions"
                  >
                    <span className="uk-margin-small-right">
                      Read More
                    </span>
                  </button>

                  {/* This is the modal */}
                  <div
                    id="modal-why-jamstack-solutions"
                    className="uk-modal-container"
                    uk-modal="true"
                  >
                    <div className="uk-modal-dialog">
                      <button
                        className="uk-modal-close-default"
                        type="button"
                        aria-label="Close"
                        uk-close="true"
                      ></button>

                      <div className="uk-modal-header">
                        <h4
                          className="uk-margin-right uk-margin-remove"
                          style={{ fontWeight: "700" }}
                        >
                          <span className="blue-grad-faqs">Jamstack Solutions - FAQs</span>
                        </h4>   
                      </div>

                      <div className="uk-modal-body" uk-overflow-auto="true">
                        <h5 className="uk-margin-remove">Jamstack</h5>
                        <p>
                          Jamstack is an architectural approach that decouples the web experience layer from data and business logic, improving flexibility, scalability, 
                          performance, and maintainability. Jamstack removes the need for business logic to dictate the web experience.                          
                        </p>

                        <p>It enables a composable architecture for the web where custom logic and 3rd party services are consumed through</p>

                        <h5 className="uk-margin-remove">APIs</h5>
                        <p>
                          API is the acronym for Application Programming Interface. It defines interactions that allows two applications to talk to each other.
                        </p>

                        <h5 className="uk-margin-remove">CDN (Content Delivery Network)</h5>
                        <p>
                          A distributed network optimized for serving assets to users. By being geographically distributed, a CDN can provide redundancy and also 
                          improve delivery performance as a result of servicing requests from the infrastructure closest to the user making the request.
                        </p>

                        <h5 className="uk-margin-remove">Decoupling</h5>
                        <p>
                          Decoupling is the process of creating a clean separation between systems or services. By decoupling the services needed to operate a site, 
                          each component part can become easier to reason about, can be independently swapped out or upgraded, and can be designated the purview of 
                          dedicated specialists either within an organization, or as a third party.
                        </p>

                        <h5 className="uk-margin-remove">Microservice</h5>
                        <p>
                          A programming paradigm where many parts of a large application are broken down into various units that have smaller responsibility. We can use 
                          Serverless or APIs for this, but it's not that APIs or Serverless are necessarily Microservices, it's that we have split apart what we want to 
                          access, and that modularity is what we call Microservices.
                        </p>

                        <h5 className="uk-margin-remove">Pre-render / Pre-generate</h5>
                        <p>
                          To generate the markup which represents a view in advance of when it is required. This happens during a build rather than on-demand so that web 
                          servers do not need to perform this activity for each request received.
                        </p>

                        <h5 className="uk-margin-remove">Server render</h5>
                        <p>
                          To generate markup on the server rather than on the client. Server render usually refers to the process happening at request time, rather than at 
                          build time. Some do use this term as a catch all for any rendering taking place on a server, and so disambiguation is often sensible.
                        </p>

                        <h5 className="uk-margin-remove">Serverless</h5>
                        <p>
                          "Serverless" or "serverless functions" are a means of providing a run-time environment for code without the need for knowledge of, or control over, 
                          the underlying environment. It allows event-driven logic on the server without setting up infrastructure.
                        </p>

                        <p>
                          Common confusion over the term stems from the observation that "there is still a server somewhere", which is true, but no servers are present in the 
                          problem space of those working with serverless functions. In a similar way, a wireless router will have wires for power and connectivity, but no wires 
                          exist in the domain of the devices utilizing a wireless router to connect to the internet.
                        </p>

                        <h5 className="uk-margin-remove">Static site generator</h5>
                        <p>
                          A tool which can be run as part of a build to transform content, data, and templates into files which can be deployed to a hosting environment as a 
                          ready-to-serve web site. 
                        </p>
                      </div>

                      <div className="uk-modal-footer uk-text-right">
                        <button
                          className="uk-button uk-button-default uk-button-small uk-margin-small-bottom buttonHover uk-animation-fade uk-modal-close"
                          style={{ color: "#0077B6", borderColor: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                          data-uk-icon="arrow-right"
                        >
                          <span className="uk-margin-small-right">
                            Close
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End of modal */}
                </div>
              </div>
              <div className="theme-card">
                <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                  <div
                    className="uk-card-badge uk-label"
                    style={{
                      background: "linear-gradient(45deg, #023E8A, #CAF0F8)",
                      fontWeight: "600" 
                    }}
                  >
                    FAQ
                  </div>
                  <div className="uk-margin-small-top">
                    <h4
                      className="uk-margin-right uk-margin-remove "
                      style={{ fontWeight: "700" }}
                    >
                      <span className="blue-grad-faqs">Why Headless CMS Solutions - FAQs</span>
                    </h4> 
                  </div>
                  
                  <p>
                    With headless CMS its agnostic, you can use your favorite frontend platform to create fast, personalized experiences 
                    for customers, without relying on one-size-fits-all monolithic solutions. 
                  </p>

                  <p>
                  Out-of-the-box code on headless solutions is quick and creates a lighter workload for developers.
                  </p>

                  <p>
                  Switching to ecommerce platforms (or other areas of your stack), you can seamlessly swap the backend and make a smooth migration.
                  </p>

                  {/* This is an anchor toggling the modal */}

                  <button
                    className="uk-button uk-button-default uk-button-small uk-text-small buttonTitle"
                    style={{ color: "#0077B6", borderColor: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                    data-uk-icon="arrow-right"
                    uk-toggle="target: #modal-why-headless-cms-solutions"
                  >
                    <span className="uk-margin-small-right">
                      Read More
                    </span>
                  </button>

                  {/* This is the modal */}
                  <div
                    id="modal-why-headless-cms-solutions"
                    className="uk-modal-container"
                    uk-modal="true"
                  >
                    <div className="uk-modal-dialog">
                      <button
                        className="uk-modal-close-default"
                        type="button"
                        aria-label="Close"
                        uk-close="true"
                      ></button>

<div className="uk-modal-header">
                        <h4
                          className="uk-margin-right uk-margin-remove"
                          style={{ fontWeight: "700" }}
                        >
                          <span className="blue-grad-faqs">Headless CMS Solutions  FAQs</span>
                        </h4>   
                      </div>

                      <div className="uk-modal-body" uk-overflow-auto="true">
                      <h5 className="uk-margin-remove">Headless technology?</h5>
                        <p>
                          The term "headless" refers to removing the dependency of knowing where data will be displayed and instead just holding the data to be 
                          used wherever the developer chooses. This is often used to describe a CMS where content can be entered, held, then where and how that 
                          content is displayed is decided separately.
                        </p>

                        <h5 className="uk-margin-remove">API?</h5>
                        <p>
                          API is the acronym for Application Programming Interface. It defines interactions that allows two applications to talk to each other.
                        </p>

                        <h5 className="uk-margin-remove">Seamless Agnostic personalization?</h5>
                        <p>
                        With a headless setup, you can use your favorite frontend platform to create fast, personalized experiences for your customers, without 
                        relying on one-size-fits-all monolithic solutions.  
                        </p>

                        <h5 className="uk-margin-remove">Extra security?</h5>
                        <p>
                          Using a monolithic frontend implies using the same backend. As such, vulnerabilities can be exploited. Keep your backend infrastructure 
                          private by going headless.
                        </p>

                        <h5 className="uk-margin-remove">Faster time-to-deployment?</h5>
                        <p>
                          Monolithic architecture is slow and difficult to scale. Out-of-the-box code on headless solutions is quick and creates a lighter workload 
                          for developers.
                        </p>
                      </div>
                      <div className="uk-modal-footer uk-text-right">
                      <button
                          className="uk-button uk-button-default uk-button-small uk-margin-small-bottom buttonHover uk-animation-fade uk-modal-close"
                          style={{ color: "#0077B6", borderColor: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                          data-uk-icon="arrow-right"
                        >
                          <span className="uk-margin-small-right">
                            Close
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End of modal */}
                </div>
              </div>
            </div>
          </div>
        </div>        
      </section>

      <ArticleSlider 
        articles={articleSlider.articles} 
        title={articleSlider.title} 
        description={articleSlider.description} 
        bg={articleSlider.bg} 
      />

      <RichText content={richTextblock.richTextBody} />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
    </>
  )
}

export default FaqsPage
