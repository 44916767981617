import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import BlocksRenderer from "../components/blocks-renderer"

const ProjectPage = ({ data }) => {
    //console.log("Project Page - data:", data)

    const { blocks, seo } = data?.strapiProjectDetail    
    const structuredData = seo?.structuredData

    return (
        <div as="website">
            <Helmet>
                <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "website",
                    structuredData,
                })}
                </script>
            </Helmet>

            <Seo
                title={seo?.metaTitle}
                description={seo?.metaDescription}
                image={seo?.metaImage?.localFile?.publicURL}
                post={false}
                metaSocial={seo?.metaSocial}
                metaRobots={seo?.metaRobots}
            />

            <BlocksRenderer blocks={blocks || []} />
        </div>
    )
}

export const pageQuery = graphql`
    query ($slug: String) {
        strapiProjectDetail(slug: { eq: $slug }) {
            slug
            seo {
                ...StrapiSeo
            }
            blocks {
                ...Blocks
            }
        }
    }
`

export default ProjectPage
