import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import UIkit from "uikit"
import Markdown from "react-markdown"
import Headings from "./headings"

const SliderSwitcherContent = ({ sections, title, description, bg }) => {

  const [hasMounted, setHasMounted] = React.useState(false)

  useEffect(() => {
    // trigger another render, reconciliation after the first mount, updating the dom with regards to rehydration
    setHasMounted(true)

    // now everything is syncned
    const util = UIkit.util

    // init switcher
    const switchEl = UIkit.switcher(".uk-subnav", {
      animation: "uk-animation-fade",
      swiping: true,
    })

    const sliderEl = UIkit.slider("[uk-slider]")

    // get slider elements
    const sliderItems = util.$$(".uk-slider-items li")

    // add active class to first slider element
    util.addClass(sliderItems[0], "active")

    // add event listener for each element
    sliderItems.forEach((item, index) => {
      UIkit.util.on(item, "itemshow", function () {
        switchEl.show(index)
        sliderEl.show(index)
        util.removeClass(sliderItems, "active")
        util.addClass(item, "active")
      })
      // go to switcher index based on slider element index
      item.addEventListener("click", () => {
        switchEl.show(index)
        //sliderEl.show(index);
        util.removeClass(sliderItems, "active")
        util.addClass(item, "active")
      })
    })
  }, [])

  if (!hasMounted) {
    setHasMounted(true)
  }

  /* if (!hasMounted) {
    return null;
  } */


  return (
    <section className={`uk-section ${bg} uk-padding-remove`} >
      <div className="uk-container" style={{ marginBottom: "20px" }}>
        <Headings
          title={title}
          description={description}
          bg={bg}
        />
        <div
          className="uk-position-relative uk-visible-toggle uk-dark"
          uk-slider="autoplay: true"
        >
          <ul className="uk-slider-items uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m">
            {sections.map((item, index) => {
              //console.log(item.image.localFile)
              return (
                <li key={index} className="active"  style={{padding: "30px" }}>
                  {item.image && (
                    <GatsbyImage
                      image={getImage(
                        item.image.localFile.childImageSharp.gatsbyImageData
                      )}
                      alt={item.image.alternativeText}
                      title={item.image.alternativeText}
                      style={{ maxHeight: "100%" }}
                    />
                  )}
                </li>
              )
            })}
          </ul>

          <a
            className="uk-position-center-left uk-position-small uk-hidden-hover"
            href="/#"
            aria-label="Previous"
            uk-slidenav-previous="true"
            uk-slider-item="previous"
          >
            {null}
          </a>
          <a
            className="uk-position-center-right uk-position-small uk-hidden-hover"
            href="/#"
            aria-label="Next"
            uk-slidenav-next="true"
            uk-slider-item="next"
          >
            {null}
          </a>
        </div>
        <hr />
        <div>
          <ul className="uk-subnav uk-hidden">
            <li>
              <a href="/">Item</a>
            </li>
            <li>
              <a href="/#">Item</a>
            </li>
            <li>
              <a href="/#">Item</a>
            </li>
            <li>
              <a href="/#">Item</a>
            </li>
            <li>
              <a href="/#">Item</a>
            </li>
            <li>
              <a href="/#">Item</a>
            </li>
          </ul>

          <ul className="uk-switcher uk-margin">
            {sections.map((item, index) => {
              //console.log("item: ", item)

              return (
                <li key={index}>
                  <div className="theme-card">
                    <div className="uk-card uk-card-body uk-card-default">
                      <div>
                        <h4>{item.title}</h4>
                      </div>
                      
                      <Markdown
                        //className="uk-padding-remove-bottom"
                        //children={item.description.data.childMarkdownRemark.html}
                        children={item.description.data.description}
                      />

                      {item.enabled ? (
                        <Link
                          to={item.link}
                          title="text"
                          alt="text"
                          data-uk-icon="arrow-right"
                          className="uk-button uk-button-default uk-button-small uk-text-small buttonTitle"
                          style={{ color: "#0077B6", borderColor: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                        >
                          <span>{item.buttonName}</span>
                        </Link>
                      ) : (
                        <button
                          disabled
                          title="Coming soon - button disabled"
                          data-uk-icon="arrow-right"
                          className="uk-button uk-button-default uk-button-small uk-text-small buttonTitle"
                          style={{ color: "#0077B6", borderColor: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                        >
                          {item.buttonName}
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default SliderSwitcherContent
