import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import SwitcherButtonsContent from "../components/switcher-buttons-content"
import ArticleSlider from "../components/article-slider"
import CtaSlider from "../components/cta-slider"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import ReactLightbox from "../components/react-lightbox"
import { Helmet } from "react-helmet"
import AboutMe from "../components/about-me"
import FeaturedComponent from "../components/featured-component"
import Timeline from "../components/timeline"
import Experience from "../components/experience"
import Education from "../components/education"
import RichText from "../components/rich-text"


const AboutMePage = () => {

  const { 
    strapiAboutUs,
  } =
  useStaticQuery(graphql`
    query {
      strapiAboutUs {
        blocks {
          ...StrapiHero
          ...StrapiHeading
          ...SwitcherSections
          ...StrapiAboutMe
          ...StrapiEducation
          ...StrapiTimeline
          ...TestimonialCards
          ...StrapiFeaturedComponent
          ...StrapiGallery
          ...ArticleSlider
          ...StrapiSharedRichText
          ...StrapiCallToAction          
        }
        seo {
          ...StrapiSeo            
        }
      }
    }
  `)

  const { blocks, seo } = strapiAboutUs
  //console.log("strapiAboutUs - Blocks: ", blocks)

  const hero = blocks[0]
  const heading = blocks[1]
  const switcher = blocks[2]  
  const aboutMe = blocks[3]
  const education = blocks[4]
  const timeline = blocks[5]
  const testimonials = blocks[6]
  const technologies = blocks[7]
  const gallery = blocks[8]
  const articleSlider = blocks[9]
  const richTextblock = blocks[10]
  const callToAction = blocks[11]

  // Helmet data seo
  const structuredData = seo.structuredData

  return (
    <>
       <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings
        title={heading.title}
        description={heading.description}
        bg={heading.bg}
      />
 
      <SwitcherButtonsContent 
        switcherSections={switcher.switcher_sections}
        bg={switcher.bg}
      />

      <AboutMe 
        aboutMe={aboutMe} 
        bg={aboutMe.bg}
      />

      <Education 
        sections={education.education_sections}
        title={education.title}
        description={education.description}
        bg={education.bg}
      />
      
      <Timeline 
        sections={timeline.timeline_sections}
        title={timeline.title}
        description={timeline.description}
        bg={timeline.bg}
      />

      <Experience bg="uk-section-default" />

      <TestimonialSliderBackgroundImage 
        backgroundImage={testimonials.backgroundImage}
        testimonial_cards={testimonials.testimonial_cards}
      />

      <FeaturedComponent 
        sections={technologies.featured_component_sections}
        title={technologies.title}
        description={technologies.description}
        bg={technologies.bg} 
      />

      <ReactLightbox
        images={gallery.files}
        title={gallery.title}
        description={gallery.description}
        bg={gallery.bg}
        //remainingImages={remainingImages}
      />
      
      <ArticleSlider 
        articles={articleSlider.articles} 
        title={articleSlider.title} 
        description={articleSlider.description} 
        bg={articleSlider.bg} 
      />

      <RichText content={richTextblock.richTextBody} />
      
      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
    </>
  )
}

export default AboutMePage
