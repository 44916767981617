import React from "react"
import Headings from "./headings"

const CommentsBubbles = () => {
  return (
    <section className="uk-section uk-section-default uk-padding-remove">
      <div className="uk-container uk-margin-medium-bottom">
        <Headings
          title="Customer Comments"
          description="I am extremely proud when I get positive comments from our clients, who are happy with my services provided. 
          Please see some of the comments below from a few of our customers"
          bg="uk-background-default"
        />
        <div
          uk-grid="true"
          className="uk-child-width-1-1 tm-comment-list uk-margin-small-top"
        >
          <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-1-1@s uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p
                    style={{ 
                      color: "#FFFFFF",  
                      textShadow: "1px 1px 2px #000000", 
                      styleLetterSpacing: "0.2em" 
                    }}
                  >
                    Herman Adu is a very experienced professional and he is able to promote personal and professional 
                    improvements among colleagues and partners.
                  </p>
                  <p
                    style={{ 
                      color: "#FFFFFF",  
                      textShadow: "1px 1px 2px #000000", 
                      styleLetterSpacing: "0.2em" 
                    }}
                  >
                    Careful expert with a great skill to analyse and solve problems. Hardworking, intelligent and 
                    helpful, he has come a long way and is sure to achieve greater heights. I would recommend him to anyone.
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m uk-flex-first@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5" 
                      style={{ 
                        color: "#0077B6",  
                      }}>                        
                    </span>
                    <br />
                    <h6 className="uk-margin-top">
                      Susan Wells
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p
                    style={{ 
                      textShadow: "1px 1px 2px #86cef5", 
                      styleLetterSpacing: "0.2em" 
                    }}
                  >
                    Herman Adu is a very experienced professional and he is able to promote personal and professional 
                    improvements among colleagues and partners.
                  </p>
                  <p
                    style={{ 
                      textShadow: "1px 1px 2px #86cef5", 
                      styleLetterSpacing: "0.2em" 
                    }}
                  >
                    helpful, he has come a long way and is sure to achieve greater heights. I would recommend him to anyone.
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left@m uk-flex-right">
                  <div className="uk-width-small uk-text-center">
                    <span 
                      uk-icon="icon: user; ratio: 2.5"
                      style={{ 
                        color: "#0077B6",  
                      }}>
                    </span>
                    <br />
                    <h6 className="uk-margin-top">Chulie Pereia</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                <p
                  style={{ 
                    color: "#FFFFFF",  
                    textShadow: "1px 1px 2px #000000", 
                    styleLetterSpacing: "0.2em" 
                  }}
                >
                  Herman Adu is a very experienced professional and he is able to promote personal and professional 
                  improvements among colleagues and partners.
                </p>
                <p
                  style={{ 
                    color: "#FFFFFF",  
                    textShadow: "1px 1px 2px #000000", 
                    styleLetterSpacing: "0.2em" 
                  }}
                >
                  Careful expert with a great skill to analyse and solve problems. Hardworking, intelligent and 
                  helpful, he has come a long way and is sure to achieve greater heights. I would recommend him to anyone.
                </p>
              </div>
              </div>
              <div className="uk-width-1-6@m uk-flex-first@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left">
                  <div className="uk-width-small uk-text-center">
                    <span 
                      uk-icon="icon: user; ratio: 2.5"
                      style={{ 
                        color: "#0077B6",  
                      }}>
                    </span>
                    <br />
                    <h6 className="uk-margin-top">Allison Flecther</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <div
              uk-grid="true"
              className="uk-grid-collapse"
              style={{ backgroungColor: "red" }}
            >
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    What would these poor little dogs do without you and
                    everyone else who helps you. The care, time and love you
                    give them is beyond amazing.
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left@m uk-flex-right">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">Lynne Jackson</h6>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </section>
  )
}

export default CommentsBubbles

