import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import BlocksRenderer from "../components/blocks-renderer"
import { Helmet } from "react-helmet"

const TermsAndConditionsPage = () => {
  const { strapiTerm } = useStaticQuery(graphql`
    query {
      strapiTerm {
        blocks {
          ...Blocks
        }
        seo {
          ...StrapiSeo
        }      
      }
    }
  `)
  //console.log("strapiTerm: ", strapiTerm)

  const { blocks, seo } = strapiTerm
  //console.log("strapiTerm - Blocks: ", blocks)

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <section className="uk-section-default">
        <BlocksRenderer blocks={blocks} />
      </section>
    </>
  )
}

export default TermsAndConditionsPage
