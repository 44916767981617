import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import FeaturedArticle from "../components/featured-article"
import CommentsBubbles from "../components/comments-bubbles"
import Champion from "../components/champion"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import CtaSlider from "../components/cta-slider"
import ShareButton from "../components/share-buttons"
import PageAdvert from "../components/page-advert"
import { Helmet } from "react-helmet"
import RichText from "../components/rich-text"

const TestimonialsPage = pageContext => {
  //console.log("TestimonialsPage - pageContext: ", pageContext)
  const { strapiTestimonial } = useStaticQuery(graphql`
    query {
      strapiTestimonial {
        blocks {
          ...StrapiHero
          ...StrapiHeading
          ...PageAdverts
          ...TestimonialCards
          ...StrapiSharedRichText
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo
          metaSocial {
            socialNetwork
            title
            description
          }
          structuredData {
            _type
            name
            url
            email
            contactPoint {
              _type
              availableLanguage
              contactType
              telephone
            }
            sameAs
            updatedAt
          }
        }
        featuredArticle {
          ...StrapiFeaturedArticle
        }
      }
    }
  `)

  const { blocks, featuredArticle, seo } = strapiTestimonial
  //console.log("strapiTestimonial - Blocks: ", blocks)
  //console.log("strapiTestimonial - featuredArticle: ", featuredArticle)
  //console.log("strapiTestimonial - seo: ", seo)

  const hero = blocks[0]
  const headings = blocks[1]
  const adverts = blocks[2]
  const testimonials = blocks[3]
  const richTextblock = blocks[4]
  const callToAction = blocks[5]

  const quote = "Please share our testimonials"
  const hashtag = "#AduDev"

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "HomePage",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings
        title={headings.title}
        description={headings.description}
        bg={headings.bg}
      />

      <PageAdvert 
        advert={adverts.page_adverts[0]} 
        bg={adverts.page_adverts[0].bg}
      />

      <PageAdvert 
        advert={adverts.page_adverts[1]} 
        bg={adverts.page_adverts[1].bg}
      />

      {/* <PageAdvert 
        advert={adverts.page_adverts[2]} 
        bg={adverts.page_adverts[2].bg}
      /> */}

      <TestimonialSliderBackgroundImage 
        backgroundImage={testimonials.backgroundImage}
        testimonial_cards={testimonials.testimonial_cards}
      />

      {/* <FeaturedReview description={content.data.content} url={pageContext.location.href} seo={seo} /> */}
      
      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-small-bottom">
          <Headings 
            title="Featured Review"
            description="Join me in celebrating the stories that define the work I do, one review at a time. Reviews are not just reflections of the past; they're beacons that guide us toward a future of continued success and client satisfaction."
          />
          <Champion />
          <div className="uk-margin-medium-top">
            <ShareButton
              //description={seo.metaDescription}
              url={pageContext.location.href}
              quote={quote}
              hashtag={hashtag}
              seo={seo}
            />
          </div>
        </div>
      </section>

      <CommentsBubbles />

      <FeaturedArticle featuredArticle={featuredArticle} />

      <RichText content={richTextblock.richTextBody} />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
    </>
  )
}

export default TestimonialsPage
