import React from "react"
import ReactLightbox from "./react-lightbox"

const BlockGallery = ({ data }) => {
  //console.log("Data: ", data)

  const { title, description, files, bg } = data

  return (
   <>
    <ReactLightbox
        images={files}
        location={`${title} Project Page`}
        title={title}
        description={description}
        bg={`${bg}`} 
        //galleryImages={galleryImages}
        //remainingImages={remainingImages}
      />
   </>
  )
}

export default BlockGallery
