import React from "react"
//import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
//import Headings from "../components/headings"

const Champion = () => {
  return (
    <div
      className="uk-grid-collapse uk-child-width-1-2@m uk-grid-match uk-flex-middle uk-light uk-margin-medium-top"
      data-uk-grid="true"
      style={{ backgroundColor: "#0077B6" }}
    >
      <div className="uk-flex-last@m">
        <div>
          <StaticImage
            src="../images/jwr-ltd-v1.jpg"
            alt="client properties in london"
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="blurred"
            title="client properties in london"
            layout="FULL_WIDTH"
            //aspectRatio={1.0}
            width={100}
          />
        </div>
      </div>
      <div>
        <div className="uk-padding">
          <h4
            className="blue-grad uk-margin-remove"
            style={{ fontWeight: "700", fontSize: "24px" }}
          >
            <span>James Walter Raymond Ltd</span>
          </h4>   
          <p
            style={{ 
              color: "#FFFFFF",  
              textShadow: "1px 1px 2px #000000", 
              styleLetterSpacing: "0.2em" 
            }} 
            //style={{ color: "#FFFFFF" }}
          >
            Herman Adu is an extremely committed and enthusiastic individual 
            that I had the pleasure to work with Lorem Ipsum is simply dummy. 
          </p>
          <p 
            style={{ 
              color: "#FFFFFF",  
              textShadow: "1px 1px 2px #000000", 
              styleLetterSpacing: "0.2em" 
            }} 
            //style={{ color: "#FFFFFF" }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum 
            has been the industry's standard dummy.
          </p>
          <h4
            className="blue-grad uk-margin-remove"
            style={{ fontWeight: "700",  }}
          >
            <span>Adam Hawkes</span>
          </h4>
          {/* <Link
            to="/article/a-day-with-chulie"
            className="hover-underline-animation-white"
            style={{ color: "#FFFFFF", textDecoration: "none" }}
          >
            Aiden Campbell
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default Champion
