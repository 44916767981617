import React from "react"
import TestimonialCard from "./testimonial-card"

const TestimonialSliderBackgroundImage = ({ testimonials, backgroundImage, testimonial_cards }) => {
  //console.log("testimonials: ", testimonials)

  return (
    <section className="uk-cover-container overlay-wrap uk-flex uk-flex-middle" style={{ minHeight: "600px", maxHeight: "850px" }}>

      { backgroundImage?.localFile?.publicURL?.length > 0 ? (
        <img
          src={backgroundImage.localFile.publicURL}
          alt={backgroundImage.alternativeText}
          title={backgroundImage.caption}
          data-uk-cover="true"
          data-uk-img="true"          
        />
      ) : null }      

      <div className="uk-container uk-position-z-index uk-position-relative uk-margin-medium-top uk-margin-medium-bottom uk-light">
        <div className="uk-grid uk-flex-center">
          <div
            className="uk-width-2-3@m removeOpacity uk-padding"
            data-uk-parallax="y: 50,-50; easing: 0; media:@l"
          >
            <div
              className="uk-position-relative uk-visible-toggle "
              data-uk-slider="easing: cubic-bezier(.16,.75,.47,1)"
            >
              <ul className="uk-padding uk-container uk-slider-items uk-child-width-2-2 ">
                {testimonial_cards.map((testimonialCard, index) => {
                  //console.log("Article: ", article)
                  return (
                    <TestimonialCard
                      key={index}
                      testimonialCard={testimonialCard}
                    />
                  )
                })}
              </ul>
              <ul className="uk-slider-nav uk-dotnav">
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialSliderBackgroundImage
