import React from "react"
import Headings from "./headings"

const BlockHeadings = ({ data }) => {
    //console.log("Block HHeadings: ", data)

    const { title, description } = data
    
    return (
        <>
            <Headings
                title={title}
                description={description}
                bg= "uk-background-default"
            />
        </>        
    )
}

export default BlockHeadings
