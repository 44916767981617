import React from 'react'

const Location = () => {
  return (
    <section className="uk-section-default uk-padding-remove">
        <div className="uk-section  uk-padding-remove">
          <div className="uk-container uk-margin-medium-bottom">
            <div style={{ marginBottom: "20px"}}>
              <h4 className="uk-margin-remove-bottom">
                My Location
              </h4>
              <div style={{ marginTop: "20px"}}>
                <h5 className="uk-margin-remove">
                  Adu Dev Freelance Web Development & Professional Managed Services
                </h5>
              </div>              
            </div>

            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.576824029657!2d-0.6748174067644183!3d51.520979237049104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876635102779a5d%3A0xc03a4ada6382b8e3!2s4%20Farm%20Cl%2C%20Taplow%2C%20Maidenhead%20SL6%200QF!5e0!3m2!1sen!2suk!4v1713981954602!5m2!1sen!2suk" 
              width="600" 
              height="450" 
              title="My Registered Office Location"
              //style={{border: "0px"}}
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

          </div>
        </div>
      </section>
  )
}

export default Location
