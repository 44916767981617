import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const BlockHero = ({ data }) => {
  //console.log("Block Hero: ", data)

  const { image, logo, title, callToAction, Buttons } = data

  return (
    <section id="home">
      <div className="uk-cover-container ">
        {image?.ext  === ".mp4" ? (
          <video
            loop 
            autoPlay 
            muted
            style={{ height: "650px", width: "100%", objectFit: "cover" }}
          >
            <source src={image?.localFile?.publicURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (          
          <GatsbyImage
            image={getImage(image?.localFile)}
            alt="Adu dev hero image"
            style={{ height: "650px" }}
          />
        )}

        <div className="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center">
          <div className="uk-flex uk-flex-center">
            <div className="uk-width-2-3@s uk-width-3-3@m">
             {logo ? (
                <div className=" uk-text-center uk-animation-fade uk-animation-slide-top uk-margin-small-bottom">
                  <GatsbyImage
                    image={getImage(logo?.localFile)}
                    alt="Adu dev logo"
                    style={{ maxWidth: "750px" }}
                  />
                </div>
              ) : (
                <div className="uk-heading-medium uk-heading-line uk-text-center uk-light uk-animation-fade uk-animation-slide-right-medium medium uk-margin-small-top">
                <h1 className="blue-grad">
                  <span className="blue-grad uk-text-large@m">{title}</span>
                </h1>
                </div>
              )}
              <div className="uk-light uk-animation-fade uk-animation-slide-right-medium medium">
                <h1 className="blue-grad">
                  <span className="blue-grad uk-text-large@m">{title}</span>
                </h1>             
              </div>
              <div className="uk-animation-fade uk-animation-slide-left-medium" 
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.1rem",
                  marginTop: "20px",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                {callToAction}
              </div>
              {/* <div className="uk-animation-fade uk-animation-slide-left-medium uk-hidden@s">
                {callToAction}
              </div> */}
              <div className="uk-flex uk-flex-center uk-flex-middle uk-visible@s">
                <div className="uk-margin-small-bottom">
                  {Buttons?.map(button => {
                    //console.log("Button:", button)
                    return (
                      <Link
                        key={button.strapi_id}
                        to={button.link}
                        title={button.name}
                        alt={button.alternativeText}
                        //rel="noopener noreferrer"
                        //target="_blank"
                        toggle="button"
                        className="uk-button uk-button-hero uk-button-small uk-text-small uk-animation-fade uk-animation-slide-bottom uk-margin-small-right uk-margin-bottom uk-margin-top"
                        style={{ borderColor: "#f7a859", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                      >
                        <span>{button.name}</span>
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockHero
