import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
//import Headings from "./headings"
import Markdown from "react-markdown"

const BlockAdvert = ({ data }) => {  
  //console.log("Block Advert Data: ", data)

  return (
    <>
      {data?.position === "image-right" ? (
        <section className={`uk-section uk-padding-remove ${data?.sectionColor}`}>
          <div className="uk-container" style={{ paddingTop: "20px", paddingBottom: "34px"}}>   
            {data?.title === null && data?.secondaryHeading?.length > 0 ? (
              <>
              <div className=" uk-margin-small-bottom">
                <h3 className="uk-margin-remove">{data?.secondaryHeading}</h3>
              </div>
              <div className="uk-margin-small-bottom" style={{ paddingBottom: "10px"}}>
                {data?.secondaryDescription ? (
                  <h5 className="uk-margin-remove">{data?.secondaryDescription}</h5>
                ) : null}                    
              </div> 
            </>                 
            ) : null}
              <div className={`uk-grid uk-child-width-1-2@l uk-flex-middle`}>
                <div>
                  {data?.title !== null ? (
                    <>
                      <div className=" uk-margin-small-bottom">
                        <h4 className="uk-margin-remove" style={{marginBottom: "20px"}}>{data?.title}</h4>
                      </div>
                      <div className=" uk-margin-small-bottom">
                        <h5 className="uk-margin-remove">{data?.description}</h5>
                      </div> 
                    </>
                  ) : null} 
                  <div className="">
                    <Markdown children={data?.content?.data?.content} />
                  </div>
                  {data?.enabled === true ? (
                    <>
                        <Link
                        to={data?.advertButton?.link}
                        alt={data?.advertButton?.alternativeText}
                        title={data?.advertButton?.alternativeText}
                        className="uk-button uk-button-default uk-button-small  buttonHover uk-visible@l"
                        data-uk-icon="arrow-right"
                        style={{ borderColor: "#0077B6", color: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                        //style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        <span className="uk-margin-small-right">
                          {data.advertButton.name}
                        </span>
                        
                      </Link>

                      <Link
                        to={data?.advertButton?.link}
                        alt={data?.advertButton?.alternativeText}
                        title={data?.advertButton?.alternativeText}
                        className="uk-button uk-button-default uk-button-small  buttonHover uk-hidden@l"
                        data-uk-icon="arrow-right"
                        style={{ borderColor: "#0077B6", color: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px" }}
                        //style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px"  }}
                      >
                        <span className="uk-margin-small-right">
                          {data.advertButton.name}
                        </span>
                        
                      </Link>
                    </>
                  ) : null}
                </div>
                  <div data-uk-scrollspy="cls: uk-animation-fade">
                    {data?.file && (
                      <GatsbyImage
                        image={getImage(data?.file?.localFile)}
                        alt={data?.file?.alternativeText}
                        title={data?.file?.alternativeText}
                        style={{ width: "100%" }}
                      />
                    )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className={`uk-section uk-padding-remove ${data?.sectionColor}`}>
              <div className="uk-container" style={{ marginTop: "34px", marginBottom: "34px"}}>    
                {data?.title === null && data?.secondaryHeading?.length > 0 ? (
                  <>
                    <div className=" uk-margin-small-bottom">
                      <h4 className="uk-margin-remove">{data?.secondaryHeading}</h4>
                    </div>
                    <div className="uk-margin-small-bottom" style={{ paddingBottom: "10px"}}>
                      {data?.secondaryDescription ? (
                        <h5 className="uk-margin-remove">{data?.secondaryDescription}</h5>
                      ) : null}                    
                    </div> 
                  </>                
                ) : null}              
                <div className="uk-grid uk-child-width-1-2@l uk-flex-middle">
                  <div
                    data-uk-scrollspy="cls: uk-animation-fade"
                    className="uk-flex-first@l"
                  >
                    {data?.file && (
                      <GatsbyImage
                        image={getImage(data?.file?.localFile)}
                        alt={data?.file?.alternativeText}
                        title={data?.file?.alternativeText}
                        style={{ width: "100%" }}
                      />
                    )}
                  </div>
                  <div className="uk-flex-first">                  
                    {data?.title !== null ? (
                      <>
                        <div className=" uk-margin-small-bottom">
                          <h4 className="uk-margin-remove">{data?.title}</h4>
                        </div>
                        <div className=" uk-margin-small-bottom">
                          <h5 className="uk-margin-remove">{data?.description}</h5>
                        </div> 
                      </>
                    ) : null}        
                    <div className="">
                      <Markdown children={data.content?.data?.content} />
                    </div>
                    {data?.enabled === true ? (
                      <>
                        <Link
                        to={data?.advertButton?.link}
                        alt={data?.advertButton?.alternativeText}
                        title={data?.advertButton?.alternativeText}
                        className="uk-button uk-button-default uk-button-small  buttonHover uk-visible@l"
                        data-uk-icon="arrow-right"
                        style={{ borderColor: "#0077B6", color: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                        //style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        <span className="uk-margin-small-right">
                          {data.advertButton.name}
                        </span>
                        
                      </Link>

                      <Link
                        to={data?.advertButton?.link}
                        alt={data?.advertButton?.alternativeText}
                        title={data?.advertButton?.alternativeText}
                        className="uk-button uk-button-default uk-button-small  buttonHover uk-hidden@l"
                        data-uk-icon="arrow-right"
                        style={{ borderColor: "#0077B6", color: "#0077B6", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px" }}
                        //style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px"  }}
                      >
                        <span className="uk-margin-small-right">
                          {data.advertButton.name}
                        </span>
                        
                      </Link>
                    </>
                    ) : null}
                  </div>
                </div>
              </div>
          </section>
        )}
     </>
  )
}

export default BlockAdvert
