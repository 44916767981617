import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import CtaSlider from "../components/cta-slider"
import ServiceGrid from "../components/service-grid"
import RichText from "../components/rich-text"
import ArticleSlider from "../components/article-slider"

const ServicesPage = () => {
    const { strapiServicePage, allStrapiServiceCard } = useStaticQuery(graphql`
      query {
        strapiServicePage {
          blocks {
            ...StrapiHero
            ...StrapiHeading
            ...ArticleSlider
            ...StrapiSharedRichText
            ...StrapiCallToAction
          }
          seo {
            ...StrapiSeo            
          }            
        }
        allStrapiServiceCard(sort: {fields: seq, order: ASC}) {
          nodes {
            title
            slug
            dataColor
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    aspectRatio: 1.5
                  )
                }
              }
            }  
          }
        }
      }
  `)

  //console.log("strapiServicePage: ", strapiServicePage)

  const { blocks, seo } = strapiServicePage
  //console.log("strapiServicePage - blocks: ", blocks)


  const hero = blocks[0]
  const heading = blocks[1]
  const articleSlider = blocks[2]
  const richTextblock = blocks[3]
  const callToAction = blocks[4]

  const serviceCards = allStrapiServiceCard

  const structuredData = seo.structuredData
 
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings
        title={heading.title}
        description={heading.description}
        bg={heading.bg}
      />

      <ServiceGrid serviceCards={serviceCards.nodes} />

      <ArticleSlider 
        articles={articleSlider.articles} 
        title={articleSlider.title} 
        description={articleSlider.description} 
        bg={articleSlider.bg} 
      />

      <RichText content={richTextblock.richTextBody} />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
      
    </>
  )
}

export default ServicesPage
