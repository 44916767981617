import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import ContactGrid from "../components/contact-grid"
import Location from "../components/location"
import RichText from "../components/rich-text"
import CtaSlider from "../components/cta-slider"

const ContactMsPage = () => {
  const { strapiContactMe } = useStaticQuery(graphql`
    query {
      strapiContactMe {
        blocks {
          ...StrapiHero
          ...StrapiHeading
          ...StrapiSharedRichText
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo            
        }
      }
    }
  `)
  
  const { blocks, seo,  } = strapiContactMe
  console.log("Contact me - blocks: ", blocks)
  //console.log("Contact me - seo: ", seo)

  const hero = blocks[0]
  const headings = blocks[1]
  const richTextblock = blocks[2]
  const callToAction = blocks[3]

  // Helmet data seo
  const structuredData = seo.structuredData


  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings
          title={headings.title}
          description={headings.description}
          bg={headings.bg}
      />

      <ContactGrid />

      <Location />

      <RichText content={richTextblock.richTextBody} />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToAction.call_to_action_sections} 
        bg={callToAction.bg} 
      />
    </>
  )
}

export default ContactMsPage
