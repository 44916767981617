import React from "react"
import { graphql } from "gatsby"
import BlocksRenderer from "../components/blocks-renderer"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

const ServicePage = ({ data }) => {

  const { blocks, seo } = data.strapiService

  const structuredData = seo.structuredData

  return (
    <div as="website">

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.metaImage?.localFile?.publicURL}
        post={false}
        //structuredData={service.seo.structuredData}
        metaRobots={seo?.metaRobots}
        metaSocial={seo?.metaSocial}
      />

      <BlocksRenderer blocks={blocks || []} />
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiService(slug: { eq: $slug }) {
      id
      slug
      blocks {
        ...Blocks
      }
      seo {
        ...StrapiSeo
      } 
    }
  }
`

export default ServicePage
