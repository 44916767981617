import React from "react"
import { useStaticQuery, graphql } from "gatsby"


import CtaSlider from "../components/cta-slider"
import RichText from "../components/rich-text"
import ArticleSlider from '../components/article-slider'
import ProjectsGrid from '../components/projects-grid'
import Headings from "../components/headings"
import Hero from "../components/hero"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

const ProjectsPage = () => {
    const { 
        strapiProjectPage,
        allStrapiProject,
    } = useStaticQuery(graphql`
        query {
            strapiProjectPage {
                blocks {
                    ...StrapiHero
                    ...StrapiHeading
                    ...ArticleSlider
                    ...StrapiSharedRichText
                    ...StrapiCallToAction
                }
                seo {
                    ...StrapiSeo
                }
            }
            allStrapiProject {
                edges{
                    node {
                        ...strapiProject
                    }
                }
            }
        }
    `)

    const { blocks, seo } = strapiProjectPage

    const hero = blocks[0]
    const heading = blocks[1]
    const articleSlider = blocks[2]
    const richTextblock = blocks[3]
    const callToAction = blocks[4]

    // Helmet data seo
    const structuredData = seo.structuredData

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "website",
                    structuredData,
                })}
                </script>
            </Helmet>

            <Seo
                title={seo.metaTitle}
                description={seo.metaDescription}
                image={seo.metaImage.localFile.publicURL}
                post={false}
                metaSocial={seo.metaSocial}
                metaRobots={seo.metaRobots}
            />

            <Hero hero={hero} />

            <Headings
                title={heading.title}
                description={heading.description}
                bg= "uk-background-default"
            />

            <ProjectsGrid projects={allStrapiProject} />

            <ArticleSlider 
                articles={articleSlider.articles} 
                title={articleSlider.title} 
                description={articleSlider.description} 
                bg={articleSlider.bg} 
            />

            <RichText content={richTextblock.richTextBody} />

            <CtaSlider 
                title={callToAction.title} 
                description={callToAction.description} 
                callToActionSections={callToAction.call_to_action_sections} 
                bg={callToAction.bg} 
            />

        </>
    )
}

export default ProjectsPage
