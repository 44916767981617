import React from "react"
import { graphql } from "gatsby"

import BlocksRenderer from "../components/blocks-renderer"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

//import Author from "../components/author"
//import ShareButtons from "../components/share-buttons"


const ArticlePage = ({ data }) => {

  //console.log("Data:", data)

  const { blocks, seo } = data.strapiArticle  

  //const quote = "Please share this article"
  //const hashtag = "#James_Walter_Raymond_Ltd"
  //const url = `${process.env.SITE_URL}${"/article/" + pageContext.slug}`

  const structuredData = seo.structuredData

  return (
    <div as="article">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "article",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={true}
        //structuredData={seo.structuredData}
        metaRobots={seo.metaRobots}
        metaSocial={seo.metaSocial}
      />
      
      <BlocksRenderer blocks={blocks || []} className="uk-margin-medium-top" />

      {/* <ShareButtons
        url={url}
        seo={seo}
        quote={quote}
        hashtag={hashtag}
      /> */}

      {/* <Author author={author} /> */}
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      # title
      # description
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      author {
        name
        email
        position
        avatar {
          localFile {
            url
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 60
                height: 60
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        articles {
          # title
          #headings {
          #  title
          #}
          slug
        }
      }
      seo {
        ...StrapiSeo
        
        structuredData {
          _type
          name
          url
          email
          contactPoint {
            _type
            availableLanguage
            contactType
            telephone
          }
          sameAs
          author {
            _type
            name		
            email
            position
          }
          updatedAt
          datePublished
         }        
        metaRobots
      }
    }
  }
`

export default ArticlePage
