import React from 'react'
import ServiceCard from "./service-card"

const ServiceGrid = ({ serviceCards }) => {
   

    return (
        <section className="uk-section-default">
            <div className="uk-container uk-margin ">
                <div uk-filter="target: .js-filter">

                    <ul className="uk-padding-remove uk-margin-remove-bottom uk-subnav uk-flex uk-flex-center">
                        <li className="uk-padding-remove uk-margin-small-bottom uk-active" uk-filter-control="[data-color='white'], [data-color='blue']">
                            <a 
                                className="uk-button uk-button-default uk-button-small uk-animation-fade uk-animation-slide-top buttonHover" 
                                href="/services/all#"
                                style={{ borderColor: "#0077B6", color: "#0077B6", borderRadius: "8px", paddingRight: "10px", paddingLeft: "10px"}}
                            >
                                All
                            </a>
                        </li>
                        <li className="uk-padding-remove uk-margin-small-bottom" uk-filter-control="[data-color='white']">
                            <a 
                                className="uk-button uk-button-default uk-button-small uk-animation-fade uk-animation-slide-top buttonHover" 
                                href="/facilities-management#"
                                style={{ borderColor: "#0077B6", color: "#0077B6", borderRadius: "8px", paddingRight: "10px", paddingLeft: "10px"}}
                            >
                                Development
                            </a>
                        </li>
                        <li className="uk-padding-remove uk-margin-small-bottom" uk-filter-control="[data-color='blue']">
                            <a 
                                className="uk-button uk-button-default uk-button-small uk-animation-fade uk-animation-slide-top buttonHover" 
                                href="/properties-services#"
                                style={{ borderColor: "#0077B6", color: "#0077B6", borderRadius: "8px", paddingRight: "10px", paddingLeft: "10px"}}
                            >
                                Managed Services
                            </a>
                        </li>            
                    </ul>
                    
                    <ul className="js-filter uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center" uk-grid="true">
                        {serviceCards.map((serviceCard, index) => {
                            //console.log("serviceCard: ", serviceCard);
                            return (
                                <li key={index} data-color={serviceCard.dataColor}>
                                    <ServiceCard {...serviceCard} />
                                </li>
                            )            
                        })} 
                    </ul>

                </div>
            </div>
        </section>
    )
}

export default ServiceGrid
