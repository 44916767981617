import React from 'react'
import HomeIcon from "../icons/home-icon-silhouette.svg"
//import EmailIcon from "../icons/emails-icon.svg"
import PhoneIcon from "../icons/phone-icon.svg"
//import DonationsIcon from "../icons/donation-icon.svg"
import CharityIcon from "../icons/charity-icon-v1.svg"
import DonationsIconV2 from "../icons/donation-icon-v2.svg"

const ContactDetails = () => {
    return (
        <div className="uk-grid-item-match uk-flex-middle uk-width-expand@m">
            <div className="uk-panel">
                <div className="uk-width-xlarge">
                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin uk-margin-medium-bottom">
                        <div className=" uk-flex-first uk-margin-medium-right">
                            <a href="mailto:info@adudev.co.uk?subject=Adu Dev Freelance Services - Info & Client support">
                                <HomeIcon
                                className="icon-color grow"
                                style={{ width: "50px", height: "50px" }}
                                />
                            </a>
                        </div>
                        <div className="uk-flex uk-flex-column uk-margin-small-right ">
                            <div
                                className="uk-text-bold uk-margin-small-right"
                                style={{ color: "#687169" }}
                            >
                                <h5 className="uk-margin-remove">For information or client support</h5>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin-medium-bottom">
                        <div className=" uk-flex-first uk-margin-medium-right">
                            <a href="mailto:admin@adudev.co.uk?subject=Adu Dev Web Development Services">
                                <DonationsIconV2
                                className="icon-color grow"
                                style={{ width: "50px", height: "50px" }}
                                />
                            </a>
                        </div>
                        <div className="uk-flex uk-flex-column">
                            <div
                                className="uk-text-bold"
                                style={{ color: "#687169" }}
                            >
                                <h5 className="uk-margin-remove">All web development services queries</h5>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin-medium-bottom">
                        <div className=" uk-flex-first uk-margin-medium-right">
                            <a href="mailto:admin@adudev.co.uk?subject=Adu Dev Professional Managed Services">
                                <CharityIcon
                                className="icon-color grow"
                                style={{ width: "50px", height: "50px" }}
                                />
                            </a>
                        </div>
                        <div className="uk-flex uk-flex-column">
                            <div
                                className="uk-text-bold"
                                style={{ color: "#687169" }}
                            >
                                <h5 className="uk-margin-remove">Professional managed services queries</h5>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin-small-bottom">
                        <div className=" uk-flex-first uk-margin-medium-right">
                            <a href="tel:07850 153 097">
                                <PhoneIcon
                                className="icon-color grow"
                                style={{ width: "50px", height: "50px" }}
                                />
                            </a>
                        </div>
                        <div className="uk-flex uk-flex-column">
                            <div
                                className="uk-text-bold"
                                style={{ color: "#687169" }}
                            >
                                <h5 className="uk-margin-remove">Give us a call today</h5>
                            </div>
                            <span
                                className="uk-text-bold"
                                style={{ color: "#687169" }}
                            >
                                <h5 className="uk-margin-remove">07850 153 097</h5>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactDetails
